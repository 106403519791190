<template>
  <div
    id="maintenance"
    class="container-fluid d-flex flex-column align-items-center"
  >
    <h3 class="super-headline3-light text-center mt-5">
      {{ $t("503.headline") }}
    </h3>
  </div>
</template>

<script>
import { getConfig } from "@/API/general.js";
import router from "@/router";

export default {
  name: "MaintenancePage",
  components: {},

  mixins: [],
  data() {
    return {};
  },
  computed: {},
  mounted() {
    this.$store.dispatch("setIsPageLoading", false);
    getConfig()
      .then(() => {
        router.push("/");
      })
      .catch((e) => console.log(e));
  },
};
</script>
<style scoped lang="scss">
#maintenance {
}
</style>
